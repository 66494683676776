import { Flex, Icon, Text } from "@appsmith/ads";
import {
  createMessage,
  EMPTY_PACKAGE_CONTROL_DESCRIPTION,
  EMPTY_PACKAGE_CONTROL_TITLE,
  // PACKAGES_CONTROL_DESCRIPTION,
  PACKAGES_CONTROL_TITLE,
} from "ee/constants/messages";
import PaneHeader from "IDE/Components/PaneHeader";
import React, { useMemo, type PropsWithChildren } from "react";
import useConsumedPackages, {
  type ConsumedPackage,
} from "./useConsumedPackages";
import { objectKeys } from "@appsmith/utils";
import styled from "styled-components";
import PackageLearnLink from "ee/pages/common/PackageLearnLink";
import { APP_LIBRARIES_PANE_WIDTH } from "constants/AppConstants";
import TruncatedTextWithTooltip from "ee/pages/common/TruncatedTextWithTooltip";
import { sortBy } from "lodash";

interface LineItemProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

const RIGHT_WIDTH = 70;
const GAP = 30;
const CONTAINER_PADDING = 2 * 15;
const LEFT_WIDTH =
  APP_LIBRARIES_PANE_WIDTH - RIGHT_WIDTH - GAP - CONTAINER_PADDING;
const PANE_HEADER_HEIGHT = 40;
const VERSION_TITLE_HEADER_HEIGHT = 30;

const StyledBox = styled(Flex)`
  background: var(--ads-color-background-secondary);
  width: var(--ads-spaces-16);
  height: var(--ads-spaces-16);
`;

const StyledEmptyContainer = styled(Flex)`
  padding: var(--ads-spaces-5) var(--ads-spaces-7);
  padding-top: var(--ads-spaces-15);
`;

const CenteredTextFlex = styled(Flex)`
  text-align: center;
`;

const StyledBody = styled(Flex)`
  padding: var(--ads-spaces-4);
  padding-top: var(--ads-spaces-5);
  height: 100%;
`;

const StyledVersionBody = styled(Flex)`
  height: calc(
    100% - ${PANE_HEADER_HEIGHT}px - ${VERSION_TITLE_HEADER_HEIGHT}px -
      ${CONTAINER_PADDING}px
  );
  overflow-y: auto;
`;

function LineItem({ left, right }: LineItemProps) {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex width={`${LEFT_WIDTH}px`}>{left}</Flex>
      <Flex width={`${RIGHT_WIDTH}px`}>{right}</Flex>
    </Flex>
  );
}

function Heading({ children }: PropsWithChildren<unknown>) {
  return (
    <Text color="var(--ads-v2-color-gray-500)" kind="body-s">
      {children}
    </Text>
  );
}

function ConsumedPackagesListItem({ name, version }: ConsumedPackage) {
  const left = useMemo(() => {
    return <TruncatedTextWithTooltip>{name}</TruncatedTextWithTooltip>;
  }, [name]);
  const right = useMemo(() => <Text>{version}</Text>, [version]);

  return <LineItem left={left} right={right} />;
}

function EmptyState() {
  return (
    <StyledEmptyContainer
      alignItems="center"
      flexDirection="column"
      gap="spaces-3"
      justifyContent="center"
    >
      <StyledBox justifyContent="center">
        <Icon name="packages-v3" size="lg" />
      </StyledBox>
      <CenteredTextFlex flexDirection="column" gap="spaces-2">
        <Text kind="heading-xs">
          {createMessage(EMPTY_PACKAGE_CONTROL_TITLE)}
        </Text>
        <Text>{createMessage(EMPTY_PACKAGE_CONTROL_DESCRIPTION)}</Text>
      </CenteredTextFlex>
      <PackageLearnLink kind="secondary" />
    </StyledEmptyContainer>
  );
}

function PackagesSection() {
  const leftTitle = useMemo(() => <Heading>Package</Heading>, []);
  const rightTitle = useMemo(() => <Heading>Version</Heading>, []);

  const consumedPackages = useConsumedPackages();
  const hasConsumedPackages = objectKeys(consumedPackages)?.length > 0;

  if (!hasConsumedPackages) return <EmptyState />;

  return (
    <>
      <PaneHeader title={createMessage(PACKAGES_CONTROL_TITLE)} />
      <StyledBody flexDirection="column" gap="spaces-2">
        <LineItem left={leftTitle} right={rightTitle} />
        <StyledVersionBody flexDirection="column" gap="spaces-3">
          {sortBy(Object.values(consumedPackages), ["name"]).map((pkg) => {
            return <ConsumedPackagesListItem key={pkg.packageId} {...pkg} />;
          })}
        </StyledVersionBody>
      </StyledBody>
    </>
  );
}

export default PackagesSection;
